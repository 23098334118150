import clsx from 'clsx';
import debounce from 'lodash/debounce';
import Link from 'next/link';
import {useCallback, useState} from 'react';
import {FC} from 'types';

import {CONTACT_EMAIL} from '@/lib/constants';
import {renderToStaticMarkup} from "react-dom/server";

const FAQItem: FC<{ title: string; content: string | React.ReactElement }> = ({
                                                                                title,
                                                                                content,
                                                                              }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className='cursor-pointer py-4' onClick={() => setOpen(!open)}>
      <h5 className='text-xl font-bold'>
        <span className='inline-block w-5'>{open ? '-' : '+'}</span>
        <span>{title}</span>
      </h5>
      <p className={clsx(open ? 'block' : 'hidden', 'mt-4 pl-5')}>{content}</p>
    </div>
  );
};

const FAQ_ITEMS = [
  {
    title: "What's ClipFM?",
    content:
      'ClipFM turns long-form video into short clips for social media. We use AI to find the best moments of your video and give you ready-to-post clips within minutes.',
  },
  {
    title: 'How does the AI find clips?',
    content: (
      <>
        We us a mixture of AI models and heuristics to find parts of your
        content that work well as clips.
      </>
    ),
  },
  {
    title: "What if I don't like the clips?",
    content: (
      <>
        Because our AI is still learning, sometimes it will make mistakes. If
        you don't like a clip, you can adjust the start/end timestamps in the
        clip editor. Our AI will get smarter over time.
      </>
    ),
  },
  {
    title: 'How many clips will I get?',
    content: (
      <>
        Our starter plan will transcript and clip up to 10 hours of content per
        month. Typically this will get you about 50-100 clips.
      </>
    ),
  },
  {
    title: 'Will Clip work for my content?',
    content: (
      <>
        Clip works best on conversational audio, like interviews and speeches.
        We're working on making it compatible with more types of content. If
        you're curious about your use case,{' '}
        <a href={`mailto:${CONTACT_EMAIL}`}>get in touch</a>.
      </>
    ),
  },
  {
    title: 'Does it work with audio-only podcasts?',
    content: (
      <>
        Coming soon: you'll be able to upload audio-only content to Clip.fm and
        we'll help generate a video to match.
      </>
    ),
  },
  {
    title: 'What languages does it support?',
    content: (
      <>
        English only. We'll add support for more languages in the future. If you
        have a specific language you'd like us to add, let us know!
      </>
    ),
  },
  {
    title: 'Will my clips go viral?',
    content: (
      <>
        We can't guarantee that your clips will go viral. Check out our YouTube,
        TikTok, and Instagram channels for some examples of how clips perform.
        We're working on training our AI to find clips that are more likely to
        go viral.
      </>
    ),
  },
  {
    title: 'Can I make my own clips?',
    content: (
      <>
        Yes! It is super easy to create a clip if you know the exact start and
        end timestamps.
      </>
    ),
  },
  {
    title: 'Do you have an affiliate program?',
    content: (
      <>
        We're launching an affiliate program in Q1 2023. You will earn 30% of
        revenue from any customer you refer to Clip.fm for up to 3 months.
        <a href={`mailto:${CONTACT_EMAIL}`}>Get in touch</a> if you're
        interested.
      </>
    ),
  },
  {
    title: 'How do I try it out?',
    content: (
      <>
        While tuning the AI to make great clips, we're working with creators
        directly.{' '}
        <Link legacyBehavior href='/get-started'>
          <a>Get started here</a>
        </Link>
        .
      </>
    ),
  },
];

const FAQSection: FC<any> = () => {
  const [items, setItems] = useState(FAQ_ITEMS);
  const [filter, setFilter] = useState('');

  const search = (_filter: string) => {
    const _filterLower = _filter.toLowerCase();
    // TODO: better search logic
    setItems(
      FAQ_ITEMS.filter((item) => {
        const titleMatch = item.title.toLowerCase().includes(_filterLower);
        let contentMatch = false;

        if (typeof item.content === 'string') {
          contentMatch = item.content.toLowerCase().includes(_filterLower);
        } else {
          // if content is a React Element, convert it to plain text before searching
          const html = renderToStaticMarkup(item.content);
          const text = html.replace(/<[^>]*>?/gm, '');
          contentMatch = text.toLowerCase().includes(_filterLower);
        }

        return titleMatch || contentMatch;
      })
    );
    // TODO: record a search event in google analytics here

    // when the form is submitted, send an event to Google Analytics
    // Record a search event in Google Analytics
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'search',
        event_category: 'FAQ',
        event_label: _filter,
      });
    }

  };

  const handleSearch = useCallback(
    debounce(
      (_filter: string) => {
        search(_filter);
      },
      500,
      {trailing: true}
    ),
    [setItems]
  );

  // Whenever the input changes, update the filter immediately and call
  // the debounced search function, which will only update at most once
  // every 500ms.
  const onChange = useCallback(
    (e: any) => {
      setFilter(e.target.value);
      if (e.target.value === '') {
        setItems(FAQ_ITEMS); // reset
      } else {
        handleSearch(e.target.value);
      }
    },
    [handleSearch]
  );

  const onSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      search(filter); // search immediately on press of enter key
    },
    [filter]
  );

  return (
    <section className='mx-auto max-w-[1300px] py-12 px-4 md:py-20'>
      <div className='block w-full flex-row items-center justify-between md:flex'>
        <h2 className='text-5xl'>FAQ</h2>
        <div>
          <form id="faq-form" onSubmit={onSubmit}>
            <input
              name='question'
              placeholder='Search...'
              className='rounded-full border border-slate-400 bg-white px-5 py-2'
              value={filter}
              onChange={onChange}
            />
          </form>
        </div>
      </div>
      <div className='mt-6 divide-y border-slate-500'>
        {items.map((item, i) => (
          <FAQItem key={i} {...item} />
        ))}
        {items.length === 0 ? (
          <div>
            No results found for <strong>{filter}</strong>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default FAQSection;
