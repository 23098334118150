import * as React from 'react';
import styles from '@/styles/GetStartedSection.module.scss';
import CTAButtons from "@/components/landing/CTAButtons";
import Balancer from "react-wrap-balancer";
import {CTA_HEADERS, CTA_VERSION} from "@/lib/constants";

const GetStartedSection: React.FC = () => (
  <section className={styles.getStarted}>
    <div className={styles.getStartedContent}>
      <div className="px-20 mx-auto max-w-[900px]">
        <h2 className="text-center text-6xl font-bold mb-10"><Balancer>{CTA_HEADERS.getStartedSection[0]}</Balancer>
        </h2>
        <CTAButtons ctaSection="Get Started" ctaVersion={CTA_VERSION} ctaHeader={CTA_HEADERS.getStartedSection[0]}/>
      </div>
    </div>
  </section>
);

export default GetStartedSection;
