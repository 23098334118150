import * as React from 'react';
import styles from '@/styles/FeaturesSection.module.scss';

export const Feature: React.FC<{
  name: string;
  description: string;
  image: string;
  alt: string;
  flipped?: boolean
}> = (props) => (
  <div
    className={`flex ${props.flipped ? "flex-col md:flex-row-reverse" : "flex-col md:flex-row"} items-center py-10 md:py-[100px]`}>
    <div className="w-full md:w-1/2 md:p-5">
      <h2 className="text-4xl md:text-5xl mb-6">{props.name}</h2>
      <p className="text-2xl">{props.description}</p>
    </div>
    <div
      className={`flex md:${props.flipped ? "justify-center md:justify-start" : "justify-center md:justify-end"} w-full md:w-1/2`}>
      <img className={styles.featuresImage} src={props.image} alt={props.alt}/>
    </div>
  </div>
);

const FeaturesSection: React.FC<{children: React.ReactNode}> = ({children}) => (
  <section className={styles.features}>
    {children}
  </section>
);

export default FeaturesSection;
